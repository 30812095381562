
import { defineComponent, ref, inject } from "vue";
import MenuOptionPopup from "@/components/atomics/MenuOptionPopup.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import { useRouter } from "vue-router";
import storage from "@/lib/storage/storageHelper";

export default defineComponent({
  name: "GuestHeader",
  components: {
    MenuOptionPopup,
    FlatButton,
    AvatarCircle,
    TextButton,
  },
  data() {
    return {
      isShowMyAccount: false,
    };
  },
  props: {
    isDark: { type: Boolean, default: true },
    fullName: { type: String, default: "" },
    email: { type: String, default: "" },
  },
  setup() {
    const isMobile = inject("isMobile");
    const isShowSidebar = ref(false);
    const router = useRouter();
    const localStorage = storage.getLocalStorage();

    const logout = () => {
      localStorage.clear();
      router.push({ name: "Login" });
    };

    const signUp = () => {
      localStorage.clear();
      router.push({ name: "SignUp" });
    };

    const toHome = () => {
      router.push({ path: "/" });
    };
    return {
      isMobile,
      isShowSidebar,
      logout,
      signUp,
      toHome,
    };
  },
});
/**
 * @vuese
 * @group Atomic/Organisms
 * footter
 */
