import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cefbeea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "header-logo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "username"
}
const _hoisted_7 = { class: "user-info-popup" }
const _hoisted_8 = { class: "user-detail" }
const _hoisted_9 = { class: "username mt-2" }
const _hoisted_10 = { class: "mail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AvatarCircle = _resolveComponent("AvatarCircle")!
  const _component_flat_button = _resolveComponent("flat-button")!
  const _component_text_button = _resolveComponent("text-button")!
  const _component_MenuOptionPopup = _resolveComponent("MenuOptionPopup")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["register-header", { 'bg-dark': _ctx.isDark, 'bg-light': !_ctx.isDark }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isDark)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: require('@/assets/logo/digitalsign-logo.svg'),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toHome && _ctx.toHome(...args)))
            }, null, 8, _hoisted_3))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: require('@/assets/logo/digitalsign-logo-black.svg'),
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toHome && _ctx.toHome(...args)))
            }, null, 8, _hoisted_4))
      ]),
      _createVNode(_component_MenuOptionPopup, null, {
        target: _withCtx(({ showMenuOption }) => [
          _createElementVNode("div", {
            class: "user",
            onClick: showMenuOption
          }, [
            _createVNode(_component_AvatarCircle, {
              avatar: require('icons/default-avatar.svg'),
              alt: "avatar",
              class: "avatar"
            }, null, 8, ["avatar"]),
            (!_ctx.isMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.fullName || ""), 1)
                ]))
              : _createCommentVNode("", true)
          ], 8, _hoisted_5)
        ]),
        options: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_AvatarCircle, {
                avatar: require('icons/default-avatar.svg'),
                alt: "avatar",
                class: "avatar"
              }, null, 8, ["avatar"]),
              _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.fullName || ""), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.email), 1),
              _createVNode(_component_flat_button, {
                onOnClick: _ctx.signUp,
                text: _ctx.$t('header.signUp'),
                class: "mt-2 w-full"
              }, null, 8, ["onOnClick", "text"]),
              _createVNode(_component_text_button, {
                text: _ctx.$t('header.logout'),
                class: "mt-2 login-btn",
                onOnClick: _ctx.logout
              }, null, 8, ["text", "onOnClick"])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ], 2))
}